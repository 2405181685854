<template>
  <div>
    <navbar v-if="!data.isLoginPage"></navbar>
    <div class="progress-container">
      <div class="progress" id="loading_progress"><div class="indeterminate"></div></div>
    </div>
    <div :class="{container: !data.isLoginPage}">
      <router-view v-slot="{ Component }">
        <transition name="fade">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
    <div class="websocket-warning" v-show="!data.isLoginPage && data.isWebsocketWarningVisible">
      <div class="container">
        <span class="btn-floating pulse fa-inverse">
          <i class="fas fa-triangle-exclamation fa-inverse fa-2x" data-fa-transform="down-2"></i>
        </span>
        {{ $t('footer.websocketLost') }}
      </div>
    </div>
  </div>
</template>

<script setup>
import Navbar from "@/components/Navbar"
import {nextTick, reactive, watch} from "vue"
import {useRoute} from "vue-router"
import {setInputFocus, showErrorToast, updateWidgets} from "./utils";
import http from '@/http'
import validation from "./validation";
//import ws from '@/websocket'

const data = reactive({
  isInitialLoadingDone: false,//vajalik selleks, et container-DIV muutuks nähtavaks siis kui andmed on kohal - TEGEMATA
  pendingRequests: 0,//kuimitu AJAX-päringut on töös - vajalik maski jaoks - TEGEMATA
  isWebsocketWarningVisible: false,// kas serveri ühenduse bänner on näha - nähtavus sõltub websocketi ühenduse olemasolust
  isLoginPage: true,
})

const route = useRoute()
watch(() => route.query, () => nextTick(onRouteChange))
const internalPreSendRequest = () => {
  document.getElementById("loading_progress").style.display = 'block'
  validation.updateErrors({})
}
http.initInterceptors({
  sendRequest: internalPreSendRequest,
  responseOk: () => {},
  response422: error => validation.updateErrors(error.response.data.fieldErrors),
  responseOtherError: () => showErrorToast('error.serverError'),
  timeout: () => showErrorToast('error.serverTimeout'),
})
//const wsHandlers = {
//  onOpen() {data.isWebsocketWarningVisible = false},
//  onMessage(message) {console.log('WS MESSAGE', message)},
//  onClose() {data.isWebsocketWarningVisible = !data.isLoginPage},
//}
const onRouteChange = () => {
  updateWidgets()
  setTimeout(setInputFocus, 100)//mõnikord on juba 100ms widgetid valmis
  setTimeout(setInputFocus, 500)//aga mõnikord läheb rohkem aega; ei tööta üldse, kui inspector on lahti
  data.isLoginPage = route.path.startsWith('/login')
//  if (!ws.isConnected() && !data.isLoginPage)
//    ws.open(process.env.VUE_APP_WSURL, wsHandlers)
}
</script>

<style lang="scss">

@import '~materialize-css/sass/materialize';

#app {
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0;
  margin-bottom: 50px;
  color: $text-color;
}
.container h1, .container h2 {text-align: left; margin-left: .25em}
.collection {border: 0;}
.collection .collection-item.avatar {min-height: 4rem}
.collection .collection-item.active {color: $secondary-color; background-color: #fff}
a, a svg.svg-inline--fa {color: $secondary-color; cursor: pointer}
a:hover, a:hover svg.svg-inline--fa {text-decoration: underline; color: lighten($secondary-color, 15%); transition: all 0.2s linear}
a svg.svg-inline--fa {margin: auto; height: 100%; font-size: 1.6rem}
input, textarea, select {color: $text-color}
.toast {background-color: $success-color; margin-left: 2rem; margin-right: 2rem}
.toast svg {margin-right: 1rem}
.fade-enter-active {transition: opacity .5s}
.fade-leave-active {transition: opacity 0s}
.fade-enter, .fade-leave-to {opacity: 0}
.btn-floating {text-align: center}
button svg {margin-right: 1rem}
.error-toast {color: white; background-color: $error-color; margin: 2rem}
.progress-container, .progress {margin: 0; height: 6px}
.websocket-warning {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $error-color;
  color: white;
  text-align: center;
  padding: 0.5rem;
}
.websocket-warning .fa-inverse {color: $error-color}
.websocket-warning .btn-floating {background-color: white; margin-right: 0.5rem}

.fade-enter-active {transition: opacity 0.5s ease}
.fade-enter-from, .fade-leave-to {opacity: 0}
</style>
