<template>
  <div>
    <!-- Tavasuuruses menüü -->
    <div class="navbar-fixed">
      <nav class="nav-extended">
        <div class="nav-wrapper">
          <router-link to="/" data-target="mobile-demo" class="sidenav-trigger">
            <span class="hide-on-med-and-up"><i class="fas fa-bars"></i></span>
          </router-link>
          <router-link to="/" class="logo-link">UserTrack</router-link>
          <ul id="nav-mobile" class="top-links right hide-on-small-and-down">
            <li><router-link to="/" class="waves-effect waves-light"><i class="fas fa-chess-board"></i> Dashboard</router-link></li>
            <li><router-link to="/form" class="waves-effect waves-light"><i class="fas fa-worm"></i> Demo vorm</router-link></li>
            <li class="lang-picker"><language-picker></language-picker></li>
            <li><a @click="onLogout" class="waves-effect waves-light logout" :title="$t('navbar.logout')"><i class="fas fa-right-from-bracket"></i></a></li>
          </ul>
        </div>
      </nav>
    </div>

    <!-- Mobiilne menüü -->
    <ul class="sidenav" id="mobile-demo">
      <li><router-link to="/" class="waves-effect waves-light"><i class="fas fa-chess-board"></i> Dashboard</router-link></li>
      <li><router-link to="/form" class="waves-effect waves-light"><i class="fas fa-worm"></i> Demo vorm</router-link></li>
      <li><div class="divider"></div></li>
      <li class="lang-picker"><language-picker :center="false" :dark-bg="false"></language-picker></li>
      <li><a @click="onLogout" class="waves-effect waves-light logout"><i class="fas fa-right-from-bracket"></i> {{ $t('navbar.logout') }}</a></li>
    </ul>
  </div>
</template>

<script setup>
import M from 'materialize-css'
import LanguagePicker from '@/components/LanguagePicker'
import websocket from '@/websocket'
import {onMounted} from "vue"

onMounted(() => M.Sidenav.init(document.querySelectorAll('.sidenav'), {closeOnClick: true}))
const onLogout = () => {
  websocket.close()
  window.localStorage.removeItem('jwt')
  window.router.push('/login')
}
</script>

<style scoped lang="scss">
a svg.svg-inline--fa {font-size: 1.5rem; height: 1.5rem; vertical-align: sub}
nav a svg.svg-inline--fa, nav a, nav svg.svg-inline--fa, nav a svg.svg-inline--fa {color: white}
.sidenav a, .sidenav svg.svg-inline--fa {color: $text-color}
a:hover {background-color: darken($primary-color, 10%); transition: background-color 1s}
.top-links a {color: white}
.logo-link {font-weight: bold; color: white}
.lang-picker {margin: 0.75rem 0rem -0.75rem 2rem}
@media only screen and (max-width: $small-screen) {.logo-link {left: 50%; transform: translateX(-50%); position: absolute}}
@media only screen and (min-width: $medium-screen-up) {nav a.sidenav-trigger {display: block;}}//muidu läheb logo suurel ekraanil vastu äärt
</style>
