<template>
  <div class="input-field col" :class="css">
    <input :id="id" type="text" class="timepicker" :placeholder="placeholder" v-model="composedValue" :class="{invalid: hasError}">
    <label :for="id" :class="{active: !!composedValue}">{{ label }}</label>
    <widget-error :id="id" :error-label-prefix="errorLabelPrefix"/>
  </div>
</template>

<script setup>
import M from "materialize-css"
import i18n from "@/i18n"
import {defineProps, defineEmits, onMounted, computed} from "vue"
import validation from "../../validation"
import {useComposedValue} from "../../utils"
import WidgetError from "./WidgetError";

const props = defineProps({
  id: {type: String, required: true},
  css: {type: String, required: false},
  modelValue: {type: String, required: false},
  label: {type: String, required: true},
  placeholder: {type: String, required: false},
  errorLabelPrefix: {type: String, required: false},
})
const hasError = computed(() => validation.hasError(props.id))
const emit = defineEmits(['update:modelValue'])
const composedValue = useComposedValue(props, emit)
let elem = null
const onPickerClosed = () => composedValue.value = elem.value
onMounted(() => {
  elem = document.getElementById(props.id)
  M.Timepicker.init(elem, {twelveHour: false, i18n: i18n.translate('picker'), onCloseEnd: onPickerClosed})
})
</script>
