export default {
	error: {
		unauthorized: '# ei tohi siseneda',
		missing: 'Täitmata',
		invalid: 'Ei sobi',
		too_long: 'Liiga pikk',
		too_short: 'Liiga lühike',
		too_small: 'Liiga väike',
		too_big: 'Liiga suur',
		serverTimeout: 'Praegu ei saa serveriga ühendust',
		serverError: 'Nüüd läks midagi valesti. Proovi brauser taaskäivitada!',
	},
	picker: {
		cancel: 'Tühista',
		clear: 'Puhasta',
		done: 'Ok',
		months: ['Jaanuar', 'Veebruar', 'Märts', 'Aprill', 'Mai', 'Juuni', 'Juuli', 'August', 'September', 'Oktoober', 'November', 'Detsember'],
		monthsShort: ['Jaan', 'Veeb', 'Mär', 'Apr', 'Mai', 'Juun', 'Juul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dets'],
		weekDays: ['Pühapäev', 'Esmaspäev', 'Teisipäev', 'Kolmapäev', 'Neljapäev', 'Reede', 'Laupäev'],
		weekdaysShort: ['Püh', 'Esm', 'Tei', 'Kol', 'Nel', 'Ree', 'Laup'],
		weekdaysAbbrev: ['P', 'E', 'T', 'K', 'N', 'R', 'L'],
		firstDay: 1,
		twelveHour: false,
	},
	login: {
		facebook: "Jätka Facebookiga",
		google: "Jätka Googlega",
		email: "E-post",
		password: "Salasõna",
		submit: "Login",
	},
	navbar: {
		logout: "Välju",
	},
	footer: {
		websocketLost: 'Ei saa serveriga ühendust',
	},
}
