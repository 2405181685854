import {createWebHistory, createRouter} from "vue-router"
import Login from "@/components/Login"
import LoginOk from "@/components/LoginOk"
import Dashboard from "@/components/Dashboard"
import DemoForm from "@/components/DemoForm"

const routes = [
	{path: '/', component: Dashboard},
	{path: '/login', component: Login},
	{path: '/login-ok', component: LoginOk},
	{path: '/form', component: DemoForm},
]

export default createRouter({history: createWebHistory(), routes})
