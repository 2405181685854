<template>
  <div class="input-field col" :class="css">
    <input :id="id" type="text" class="datepicker" :placeholder="placeholder" v-model="composedValue" :class="{invalid: hasError}">
    <label :for="id" :class="{active: !composedValue}">{{ label }}</label>
    <widget-error :id="id" :error-label-prefix="errorLabelPrefix"/>
  </div>
</template>

<script setup>
import i18n from "@/i18n"
import M from "materialize-css"
import {defineProps, onMounted, computed, defineEmits} from "vue";
import validation from "../../validation";
import {useComposedValue} from "../../utils";
import WidgetError from "./WidgetError";

const props = defineProps({
  id: {type: String, required: true},
  css: {type: String, required: false},
  modelValue: {type: String, required: false},
  label: {type: String, required: true},
  placeholder: {type: String, required: false},
  errorLabelPrefix: {type: String, required: false},
})
const hasError = computed(() => validation.hasError(props.id))
const emit = defineEmits(['update:modelValue'])
const composedValue = useComposedValue(props, emit)
let elem = null
const onPickerClosed = () => composedValue.value = elem.value
onMounted(() => {
  elem = document.getElementById(props.id)
  const labels = i18n.translate('picker')
  const domInstance = M.Datepicker.init(elem, {
    twelveHour: labels.twelveHour, firstDay: labels.firstDay, i18n: labels, format: 'yyyy-mm-dd',
    onClose: onPickerClosed
  })
  domInstance.setDate(new Date(Date.parse(composedValue)))
})
</script>

<style>
.datepicker-controls .select-month input {width: 6em}
</style>
