<template>
  <span class="helper-text" :data-error="fieldMessage"></span>
</template>

<script setup>
import {computed, defineProps} from "vue";
import validation from "../../validation";

const props = defineProps({
  id: {type: String, required: true},
  errorLabelPrefix: {type: String, required: false, default: 'error'},
})
const fieldMessage = computed(() => validation.getFieldMessage(props.id))
</script>
