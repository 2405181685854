<template>
  <div/>
</template>

<script setup>
  // Aktiveerub pärast google/facebook autentimist, salvestab URL-st jwt ja suundub dashboardile
  const jwt = window.location.search.split('&')[0].split('=')[1] //eeldame, et jwt on esimene parameeter
  window.localStorage.setItem('jwt', jwt)
  window.router.push('/')
</script>
