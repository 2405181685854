<template>
  <div class="row login-view">
    <div class="col s12 m5 valign-wrapper login-left">
      <div class="centered-content">
        <h1>User Track</h1>
        <language-picker></language-picker>
      </div>
    </div>
    <div class="col s12 m7 valign-wrapper login-right">
      <div class="row center-align login-options-container">
        <div class="col s12 social-login">
          <a :href="`${baseUrl}/oauth2/authorization/facebook`" class="waves-effect waves-light btn button-logo">
            <img class="material-icons left" src="@/assets/facebook.png">
            {{ $t('login.facebook') }}
          </a>
        </div>
        <div class="col s12 social-login">
          <a :href="`${baseUrl}/oauth2/authorization/google`" class="waves-effect waves-light btn button-logo">
            <img class="material-icons left" src="@/assets/google.png">
            {{ $t('login.google') }}
          </a>
        </div>
        <div class="divider col s10 offset-s1"></div>
      </div>
    </div>

  </div>
</template>

<script setup>
import LanguagePicker from "@/components/LanguagePicker"
import ws from '@/websocket'
import {onMounted} from "vue"
import {hideSpinner, showErrorToast, updateWidgets} from "../utils"
import {useRoute} from "vue-router";

const baseUrl = process.env.VUE_APP_BASEURL
onMounted(() => {
  console.log('BASEURL', baseUrl, process.env)
  ws.close()
  window.localStorage.removeItem('jwt')
  updateWidgets()
  hideSpinner()
  const query = useRoute().query
  if (query.error)
    showErrorToast(`error.${query.error}`, query.email)
})
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css?family=Orbitron');

.row {margin-bottom: 0;}
.login-view {position: absolute; left: 0; top: 0; right: 0; bottom: 0}
.login-left {height: 100%; background-color: $primary-color; color: white}
.login-left h1 {font-family: 'Orbitron', sans-serif; font-weight: bold;}
.login-left li {list-style-type: initial; text-align: left}
.centered-content {margin-left: auto; margin-right: auto; margin-bottom: 25%}
.login-left-bullets {display: inline-block}
.login-right {height: 100%}
.login-options-container a {width: 20rem}
.login-credentials {max-width: 20rem; margin-top: 2rem}
.button-logo img {margin-top: 2px; margin-right: 1em;}
.button-logo {padding-left: 2px}
.social-login {margin-bottom: 1rem}
.divider {margin-top: 1rem}
@media only screen and (max-width: 600px) {
  .login-right {height: 70%}
  .login-left {height: 30%}
}
</style>
