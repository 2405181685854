<template>
  <div>
    Vorm <i class="fas fa-user fa-bounce"></i><br>
    <div class="row">
      <form-input id="f1" v-model="data.f1" label="Tekstiväli" css="s6 m4 l3" :autocomplete-options="data.f1opts"></form-input>
      <form-select id="f2" v-model="data.f2" label="Rippmenüü" css="s6 m4 l3" :options="data.f2opts" :has-empty-option="false"></form-select>
      <form-checkbox id="f3" v-model="data.f3" label="Tšekk" css="s6 m4 l3"></form-checkbox>
      <form-text-area id="f4" v-model="data.f4" label="Suur tekst" css="s12"></form-text-area>
      <time-picker id="f5" v-model="data.f5" label="Vali aeg" placeholder="12:34" css="s6 m4 l3"></time-picker>
      <time-picker id="f6" v-model="data.f6" label="Vali aeg" placeholder="12:34" css="s6 m4 l3"></time-picker>
      <date-picker id="f7" v-model="data.f7" label="Vali päev" placeholder="2021-02-22" css="s6 m4 l3"></date-picker>
      <date-picker id="f8" v-model="data.f8" label="Vali teine päev" placeholder="2023-08-04" css="s6 m4 l3"></date-picker>
    </div>
    <on-off-switch v-model="data.f9" label-on="Sees" label-off="Väljas"></on-off-switch><br>
    <button class="btn waves-effect waves-light" @click="dump">Check</button>
  </div>
</template>

<script setup>
import FormInput from "@/components/widgets/FormInput"
import FormSelect from "@/components/widgets/FormSelect"
import FormTextArea from "@/components/widgets/FormTextArea"
import FormCheckbox from "@/components/widgets/FormCheckbox"
import OnOffSwitch from "@/components/widgets/OnOffSwitch"
import TimePicker from "@/components/widgets/TimePicker"
import DatePicker from "@/components/widgets/DatePicker"
import {onMounted, reactive} from "vue";
import validation from '@/validation'
import {hideSpinner, updateWidgets} from "../utils"

const data = reactive({
  f1: 'lühike tekst',
  f1opts: ['kala', 'liha', 'piim', 'vorst'],
  f2: 'o2',
  f2opts: [{id: 'o1', label: 'valik1'}, {id: 'o2', label: 'valik2'}, {id: 'o3', label: 'valik3'}, ],
  f3: null,
  f4: 'ajee',
  f5: '14:52',
  f6: '9:03',
  f7: '2025-02-15',
  f8: '2023-08-04',
  f9: true,
})
onMounted(() => {
  updateWidgets()
  hideSpinner()

  setTimeout(() => {
    data.f2opts = [{id: 'o1', label: 'uus1'}, {id: 'o2', label: 'uus2'}, {id: 'o3', label: 'uus33'}, {id: 'o4', label: 'uus4'}, ]
  }, 3000)
})
const dump = () => {
  validation.updateErrors(validation.hasError('f4') ? {} : {f4:'too_short', f5:'too_short', f7:'too_short'})
  console.log('Form dump', JSON.parse(JSON.stringify(data)))
}
</script>
