<template>
  <div class="input-field col" :class="css">
    <select :id="id" v-model="composedValue" :class="{invalid: hasError}">
      <option v-if="hasEmptyOption"></option>
      <option v-for="opt in options" :key="opt.id" :value="opt.id" :selected="opt.id === modelValue">{{ opt.label }}</option>
    </select>
    <label :for="id">{{ label }}</label>
    <widget-error :id="id" :error-label-prefix="errorLabelPrefix"/>
  </div>
</template>

<script setup>
import M from "materialize-css"
import WidgetError from "./WidgetError";
import {defineProps, computed, defineEmits, onMounted, watch, nextTick} from "vue";
import validation from '@/validation'
import {useComposedValue} from "../../utils";

const props = defineProps({
  id: {type: String, required: true},
  css: {type: String, required: false},
  modelValue: {required: false},
  label: {type: String, required: true},
  options: {type: Array, required: false},
  hasEmptyOption: {type: Boolean, required: false, default: true},
  errorLabelPrefix: {type: String, required: false},
})

const hasError = computed(() => validation.hasError(props.id))
const emit = defineEmits(['update:modelValue'])
const composedValue = useComposedValue(props, emit)
const reinitOptions = () => {
  const elem = document.getElementById(props.id)
  M.FormSelect.init(elem, {dropdownOptions: {container: document.body}})
}
watch(() => props.options, () => nextTick(reinitOptions), {deep: true})
onMounted(reinitOptions)
</script>
