<template>
  <div class="chart-container">
    <canvas ref="tgtChart"></canvas>
  </div>
</template>

<script setup>
import {watch, defineProps, ref} from "vue"
import {Chart, registerables} from "chart.js"

const props = defineProps(['series', 'title'])
const tgtChart = ref(null)
const renderChart = () => {
  console.log('rendering chart', JSON.parse(JSON.stringify(props.series)), tgtChart)
  Chart.register(...registerables)
  const ctx = tgtChart.value
  new Chart(ctx, {
    type: 'bar',
    data: {
      labels: props.series.labels,
      datasets: [{
        data: props.series.counts,
        borderWidth: 1
      }]
    },
    options: {
      plugins: {
        title: {
          display: true,
          text: props.title
        }
      },
      scales: {
        y: {
          beginAtZero: true,
          ticks: {precision: 0},
        },
      }
    },
  })
}
watch(() => props.series, renderChart)
</script>

<style scoped>
.chart-container {background-color: antiquewhite; margin-bottom: 1rem}
</style>
