<template>
  <div class="input-field col" :class="css">
    <label>
      <input type="checkbox" :checked="modelValue" :class="{invalid: hasError}"
             @change="$emit('update:modelValue', !modelValue)"/>
      <span class="checkbox-label">{{ label }}</span>
    </label>
    <widget-error :id="id" :error-label-prefix="errorLabelPrefix"/>
  </div>
</template>

<script setup>
import {computed, defineProps} from "vue";
import validation from '@/validation'
import WidgetError from "./WidgetError";

const props = defineProps({
  id: {type: String, required: true},
  css: {type: String, required: false},
  modelValue: {type: Boolean, required: false, default: false},
  label: {type: String, required: true},
  errorLabelPrefix: {type: String, required: false},
})

const hasError = computed(() => validation.hasError(props.id))
</script>

<style scoped>
.checkbox-label {width: 100%}
.input-field {width: 100%}
</style>
