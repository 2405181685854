<template>
  <div>
    <h1>10 päeva statistika</h1>
    <div class="row">
      <div class="col s12 l6">
        <count-bar-chart :series="data.clicksPerPage" title="Lehe vaatamisi"/>
      </div>
      <div class="col s12 l6">
        <count-bar-chart :series="data.visitsPerDay" title="Külastused päevade kaupa"/>
      </div>
      <div class="col s12 l6">
        <doughnut-chart :series="data.visitsPerCountry" title="Külastused riigiti"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import http from '@/http'
import {onMounted, reactive} from "vue"
import {hideSpinner} from "@/utils"
import CountBarChart from "@/components/CountBarChart"
import DoughnutChart from "@/components/DoughnutChart";

const data = reactive({
  clicksPerPage: [],
  visitsPerDay: [],
  visitsPerCountry: [],
})

const renderChart = chartData => {
  console.log('DATA', chartData)

  data.clicksPerPage = {
    labels: [...chartData.pageViewsPerDay].map(el => el.item),
    counts: [...chartData.pageViewsPerDay].map(el => el.count)
  }

  data.visitsPerDay = {
    labels: [...chartData.visitsPerDay].map(el => el.date),
    counts: [...chartData.visitsPerDay].map(el => el.count)
  }

  data.visitsPerCountry = {
    labels: [...chartData.visitsPerCountry].map(el => el.item),
    counts: [...chartData.visitsPerCountry].map(el => el.count)
  }

  hideSpinner()
}
onMounted(() => http.get('/ui/usage').then(response => renderChart(response.data)))
</script>

<style>
.chart-container {background-color: antiquewhite}
</style>
