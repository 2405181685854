export default {
	error: {
		unauthorized: '# is not allowed to login',
		missing: 'Missing',
		invalid: 'Invalid',
		too_long: 'Too long',
		too_short: 'Too short',
		too_small: 'Too small',
		too_big: 'Too big',
		serverTimeout: 'Unable to contact server',
		serverError: 'Something went wrong. Try restarting browser!',
	},
	picker: {
		cancel: 'Cancel',
		clear: 'Clear',
		done: 'Done',
		months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
		monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
		weekDays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
		weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
		weekdaysAbbrev: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
		firstDay: 0,
		twelveHour: true,
	},
	login: {
		facebook: "Login with Facebook",
		google: "Login with Google",
		email: "E-mail",
		password: "Password",
		submit: "Login",
	},
	navbar: {
		logout: "Logout",
	},
	footer: {
		websocketLost: 'Server connection lost',
	},
}
