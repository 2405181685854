<template>
  <div class="input-field col" :class="css">
    <textarea :id="id" class="materialize-textarea" type="text"
              v-model="composedValue"
              :placeholder="placeholder"
              :class="{invalid: hasError}">
    </textarea>
    <label :for="id">{{ label }}</label>
    <widget-error :id="id" :error-label-prefix="errorLabelPrefix"/>
  </div>
</template>

<script setup>
import M from "materialize-css"
import {computed, defineProps, defineEmits, onMounted} from "vue"
import validation from '@/validation'
import {useComposedValue} from "../../utils";
import WidgetError from "./WidgetError";

const props = defineProps({
  id: {type: String, required: true},
  css: {type: String, required: false},
  modelValue: {type: String, required: false},
  label: {type: String, required: true},
  placeholder: {type: String, required: false},
  errorLabelPrefix: {type: String, required: false},
})

onMounted(() => M.textareaAutoResize(document.getElementById(props.id)))
const hasError = computed(() => validation.hasError(props.id))
const emit = defineEmits(['update:modelValue'])
const composedValue = useComposedValue(props, emit)
</script>
