<template>
  <div class="input-field col" :class="css">
    <input :id="id" :type="type"
           v-model="composedValue"
           :class="{invalid: hasError, autocomplete: autocompleteOptions != null}"
           :placeholder="placeholder"
           :readonly="readonly"
           :autocomplete="autocompleteOnOff"
    >
    <label :for="id">{{ label }}</label>
    <widget-error :id="id" :error-label-prefix="errorLabelPrefix"/>
  </div>
</template>

<script setup>
import M from "materialize-css"
import {computed, defineProps, defineEmits, onMounted, watch} from "vue"
import validation from '@/validation'
import {useComposedValue} from "../../utils";
import WidgetError from "./WidgetError";

const props = defineProps({
  id: {type: String, required: true},
  css: {type: String, required: false},
  type: {type: String, required: false, default: 'text'},
  modelValue: {required: false},
  label: {type: String, required: true},
  placeholder: {type: String, required: false},
  readonly: {type: Boolean, required: false, default: false},
  autocompleteOptions: {type: Array, required: false},
  errorLabelPrefix: {type: String, required: false},
})

const autocompleteOnOff = computed(() => (props.autocompleteOptions == null ? "on" : "off"))
const hasError = computed(() => validation.hasError(props.id))
let instance = null
const emit = defineEmits(['update:modelValue'])
const composedValue = useComposedValue(props, emit)

onMounted(() => {
  if (props.autocompleteOptions) {
    const elem = document.getElementById(props.id)
    const opts = {}
    props.autocompleteOptions.forEach(o => opts[o] = null)
    instance = M.Autocomplete.init(elem, {data: opts})[0]
  }
})

//TODO Siin on veel mingi kaka. Testida ära autocomplete options
watch(() => props.autocompleteOptions, () => {
  const opts = {}
  props.autocompleteOptions.forEach(o => opts[o] = null)
  instance.updateData(opts)
})
</script>
