<template>
  <div class="chart-container">
    <canvas ref="tgtChart"></canvas>
  </div>
</template>

<script setup>
import {defineProps, ref, watch} from "vue"
import {Chart, registerables} from "chart.js"

const props = defineProps(['series', 'title'])
const tgtChart = ref(null)
const renderChart = () => {
  console.log('rendering pie', tgtChart, props.series)
  const config = {
    type: 'doughnut',
    data: {
      labels: props.series.labels,
      datasets: [{
        data: props.series.counts,
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(54, 162, 235)',
          'rgb(255, 205, 86)',
          '#42997B',
          '#15E0BB',
        ],
        hoverOffset: 4
      }]
    },
    options: {
      plugins: {
        title: {
          display: true,
          text: props.title
        }
      }
    },
  }
  Chart.register(...registerables)
  const ctx = tgtChart.value
  new Chart(ctx, config)
}
watch(() => props.series, renderChart)
</script>

<style scoped>
.chart-container {background-color: antiquewhite; margin-bottom: 1rem}
</style>
