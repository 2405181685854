/*
Hoiab vormi valideerimisvigu. Loogika on selline:
1) axios http response interceptor registreerib siia väljade vead (updateErrors)
2) iga vormi widget defineerib endale computed propertid, mis siit getFieldMessage abil vigu pärivad
 */

import {reactive} from "vue"
import i18n from "@/i18n"

const reactErrors = reactive({errors: {}})

export default {
	updateErrors(errors) {reactErrors.errors = errors},
	hasError(id) {return reactErrors.errors[id]},
	getFieldMessage(id) {return i18n.getError(reactErrors.errors[id])}
}
