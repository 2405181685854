//import M from 'materialize-css'
//import http from '@/http'
import ws from '@/websocket'
//import {updateWidgets} from "./utils";

window.isMounted = false // vältimaks Mixin.mounted() korduvaktiveerimist
window.isCreated = false // vältimaks Mixin.created() korduvaktiveerimist
window.routeChanged = false // vältimaks vaate vahetumisel tegevuste dubleerimist

export default {
	methods: {
/*		setInputFocus() {
			const widgets = document.querySelectorAll('textarea, input')
			if (widgets != null && widgets.length > 0) widgets[0].focus()
		},*/
/*		internalPreSendRequest(config) {
			this.$root.errors = {}
			const jwt = window.localStorage.getItem('jwt')
			if (jwt)
				config.headers.Authorization = `Bearer ${jwt}`
		},*/
/*		internalTimeoutAction() {
			M.toast({html: '<i class="fas fa-triangle-exclamation fa-2x"></i> Praegu ei saa serveriga ühendust', classes: 'error-toast', displayLength: 5000})
		},*/
/*		internalErrorResponseAction(error) {
			M.toast({html: '<i class="fas fa-triangle-exclamation fa-2x"></i> Nüüd läks midagi valesti. Taaskäivita brauser ja proovi uuesti', classes: 'error-toast', displayLength: 5000})
			console.log('Error details', error.response)
		},*/
/*		routeChanged() {
			window.routeChanged = false
			updateWidgets()
			setTimeout(this.setInputFocus, 100)//mõnikord on juba 100ms widgetid valmis
			setTimeout(this.setInputFocus, 500)//aga mõnikord läheb rohkem aega
			this.$nextTick(updateWidgets)
			setTimeout(updateWidgets, 500)
			if (!ws.isConnected())
				this.setupWebsocket()
		},*/
		setupWebsocket() {
			ws.openWebsocket(`${process.env.VUE_APP_WSURL}/ws/events`,
				() => this.$root.isWebsocketWarningVisible = false,
				() => this.$root.isWebsocketWarningVisible = true,
				m => console.log('Message from websocket', m))//TODO siia asenda websocketi sõnumi händler
		},
	},
/*	mounted() {
		if (window.isMounted)
			return
		window.isMounted = true
		M.AutoInit(null)//autoinit aktiveeri ainult ühe korra lehe laadimisel
		this.setupWebsocket()
	},*/
/*	created() {
		if (window.isCreated)
			return
		window.isCreated = true
		http.initInterceptors({
			sendRequest: this.internalPreSendRequest,
			responseOk: () => {},
			response422: error => this.$root.errors = error.response.data.fieldErrors,
			responseOtherError: this.internalErrorResponseAction,
			timeout: this.internalTimeoutAction,
		})
	},*/
/*	watch: {
		'$route'() {
			if (!window.routeChanged) {
				window.routeChanged = true
				this.$nextTick(this.routeChanged)
			}
		}
	}*/
}
